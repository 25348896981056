import { useCallback, useState } from 'react';

export default function useToggle(maxCount?: number) {
  const [toggleIndex, setToggleIndex] = useState(0);

  const onToggleClicked = useCallback(
    (index: number) => {
      if (maxCount == 2) {
        setToggleIndex((prev) => (prev + 1) % 2);
      } else {
        setToggleIndex(index);
      }
    },
    [maxCount],
  );

  return {
    toggleIndex,
    onToggleClicked,
  };
}
