import { Point } from '@nivo/line';
import React from 'react';
import '../styles/tooltip.style.css';

type Props = {
  point: Point;
  xFormatted?: string;
  yFormatter?: string;
};

const Tooltip = ({ point: { data }, xFormatted, yFormatter }: Props) => {
  return (
    <div className='tooltip-root'>
      <span className='x-value'>{xFormatted}</span>
      {yFormatter && (
        <span className='y-value'>
          <strong>{data.y.toLocaleString()}</strong>
          <span>{yFormatter.replaceAll('X', '')}</span>
        </span>
      )}
    </div>
  );
};

export default Tooltip;
