import React from 'react';
import './styles/dropdown.style.css';

type Props = {
  options: string[];
  activeIndex: number;
  isFocused: boolean;
  onDropdownClicked: () => void;
  onOptionSelected: (index: number) => void;
};

const Dropdown = ({
  options,
  activeIndex,
  isFocused,
  onDropdownClicked,
  onOptionSelected,
}: Props) => {
  return (
    <div
      className={`dropdown-root ${isFocused ? 'focused' : ''}`}
      onClick={onDropdownClicked}>
      <button className='dropdown-box'>
        <span className='text'>{options[activeIndex]}</span>
        <div className='arrow' />
      </button>
      <div className='options-wrapper'>
        <ul className='option-list'>
          {options.map((option, index) => (
            <li
              className={'option' + (index === activeIndex ? ' selected' : '')}
              key={index}
              onClick={() => {
                onOptionSelected(index);
                onDropdownClicked();
              }}>
              {option}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
