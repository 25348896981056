import React, { useEffect, useState } from 'react';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import BarChart from '../BarChart';
import { BarData } from '@typedef/bar.chart.types';

type Props = {
  data: BarData[];
  indexBy: string;
  valueKey: string;
};

function getInterver(min: number, max: number) {
  let count = 0;
  let diff = (max - min) / 2;

  while (diff >= 10) {
    diff /= 10;
    count++;
  }

  return Number(diff.toFixed(0)) * 10 ** count;
}

function getTicks(max: number, interval: number) {
  let top = Math.ceil(max / interval) * interval;

  return Array(5)
    .fill(0)
    .map((_, index) => top - index * interval);
}

const BarChartContainer = ({ data, indexBy, valueKey }: Props) => {
  //1. x축 계산
  //2. y축 tick(5개), 간격, 범위 계산

  const [xAxes, setXAxes] = useState<(string | number)[]>([]);
  const [yAxes, setYAxes] = useState<(string | number)[]>([]);
  const [values, setValues] = useState<number[]>([]);
  const [interval, setInterval] = useState(0);

  useEffect(() => {
    if (data) {
      const x = data.map((value) => value[indexBy]);
      const v = data.map((value) => value[valueKey] as number);
      setXAxes(x);
      setValues(v);
    }
  }, [data]);

  useEffect(() => {
    if (values) {
      const max = Math.max(...values);
      const min = Math.min(...values);

      const interval = getInterver(min, max);
      setInterval(interval);
      const ticks = getTicks(max, interval);
      setYAxes(ticks);
    }
  }, [values]);

  return (
    <BarChart ylabel={'(개수)'} yaxes={yAxes} xaxes={xAxes} values={values} />
  );
};

export default BarChartContainer;
