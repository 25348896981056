import RootNavigation from '@routes/RootNavigation';
import { PageType } from '@typedef/page.types';
import React from 'react';
import { useLocation } from 'react-router-dom';

const RootNavigationContainer = () => {
  return <RootNavigation />;
};

export default RootNavigationContainer;
