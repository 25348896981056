import { LoginResponseType } from '@typedef/login.typs';
import { useCallback } from 'react';

export const TOKEN_KEY = 'ast@rtsd';

export function useToken() {
  const __getToken = useCallback<() => LoginResponseType>(() => {
    let value = sessionStorage.getItem(TOKEN_KEY) || '';
    try {
      return JSON.parse(value);
    } catch {
      return {
        accessToken: '',
        refreshToken: '',
      };
    }
  }, []);

  const __updateToken = useCallback((access: string, refresh?: string) => {
    const { refreshToken } = __getToken();
    let stringifiedItem;
    if (!refresh) {
      stringifiedItem = JSON.stringify({
        accessToken: access,
        refreshToken,
      });
    } else {
      stringifiedItem = JSON.stringify({
        accessToken: access,
        refreshToken: refresh,
      });
    }
    sessionStorage.setItem(TOKEN_KEY, stringifiedItem);
  }, []);

  const __removeToken = useCallback(() => {
    sessionStorage.removeItem(TOKEN_KEY);
  }, []);

  return {
    __getToken,
    __updateToken,
    __removeToken,
  };
}
