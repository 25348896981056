import { TOKEN_KEY, useToken } from '@hooks/useToken';
import { LoginResponseType, RefreshTokenType } from '@typedef/login.typs';
import { rejects } from 'assert';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export type ErrorResponseType = {
  message: string;
  statusCode: number;
};

//현재 location기준으로 api origin 변경

export const API_ORIGIN = 'https://dev-wecobot.wimcorp.dev';

const REFRESH_STATUS = 1000;

axios.defaults.baseURL = `${API_ORIGIN}/api`;

export const apiRoute = {
  login: '/auth/login',
  dummy: '/robot/info/dummy',
  refresh: '/auth/refresh',
};

axios.interceptors.request.use(
  (res) => {
    return res;
  },
  (e) => {
    console.log('[Axios Request Error]', e);
    return e;
  },
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (e) => {
    console.log('[Axios Response Error]', e);

    const status = e?.response?.status;
    const config = e?.response?.config;

    console.log('check', status, config.url);
    if (config.url === apiRoute.login) {
      return Promise.reject(e);
    }

    if (
      config?.url !== apiRoute.refresh &&
      (status === 401 || status === 403)
    ) {
      //access token만료시 refresh token으로 accessToken 재발급

      let token: LoginResponseType = JSON.parse(
        sessionStorage.getItem(TOKEN_KEY) ||
          '{"accessToken":"","refreshToken":""}',
      );

      console.log('refresh token');
      const {
        data,
        config: { status },
      } = await requestSecureGet<RefreshTokenType>(
        apiRoute.refresh,
        {},
        token.refreshToken,
      );

      if (status === 200) {
        let stringifiedItem;

        stringifiedItem = JSON.stringify({
          accessToken: data.accessToken,
          refreshToken: token.refreshToken,
        });

        sessionStorage.setItem(TOKEN_KEY, stringifiedItem);

        let newConfig = Object.assign({}, config);

        newConfig.headers.Authorization = `Bearer ${data.accessToken}`;

        return axios(newConfig);
      }

      if (status === 401) {
        sessionStorage.removeItem(TOKEN_KEY);
        return Promise.reject(e);
      }
    } else if (
      config?.url === apiRoute.refresh &&
      (status === 401 || status === 403)
    ) {
      //refresh token 만료시 로그인 페이지로 이동
      const navigate = useNavigate();
      navigate('/login');
    }

    return Promise.reject(e);
  },
);

export type BasicApiResponse<T> = {
  data: T;
  config: {
    status: number;
    message: string;
  };
};

export type BasicListDataType<T> = {
  rows: T[];
  page: number;
  maxPage: number;
};

export function requestGet<T>(
  url: string,
  header: object,
): Promise<BasicApiResponse<T>> {
  return axios
    .get(url, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then((res) => {
      if (res.status === REFRESH_STATUS) {
        return axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${res.data.accessToken}`,
            ...header,
          },
        });
      } else {
        return res;
      }
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecureGet<T>(
  url: string,
  header: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestDelete<T>(
  url: string,
  header: object,
): Promise<BasicApiResponse<T>> {
  return axios
    .delete(url, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecureDelete<T>(
  url: string,
  header: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecurePost<T>(
  url: string,
  header: object,
  body: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: err.response.data.message,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestPost<T>(
  url: string,
  header: object,
  body: object,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
          message: err.response.data.message,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestSecurePatch<T>(
  url: string,
  header: object,
  body: object,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .patch(url, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            message: res.data.message,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
          message: '[Axios Error]' + err,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestMultipartPost<T>(
  url: string,
  header: object,
  body: FormData,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .post(url, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}

export function requestMultipartPatch<T>(
  url: string,
  header: object,
  body: FormData,
  token: string,
): Promise<BasicApiResponse<T>> {
  return axios
    .patch(url, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        ...header,
      },
    })
    .then(
      (res) =>
        ({
          data: res.data as T,
          config: {
            status: res.status,
            ...res.data?.meta,
          },
        } as BasicApiResponse<T>),
    )
    .catch((err) => {
      console.error('[Axios Error]', err);

      return {
        data: {} as T,
        config: {
          status: -1,
        },
      } as BasicApiResponse<T>;
    });
}
