import BarChartContainer from '@components/common/BarChart/containers/BarChartContainer';
import CategoryContainer from '@components/common/Category/containers/CategoryContainer';
import ColorChip from '@components/common/ColorChip/ColorChip';
import DropdownContainer from '@components/common/Dropdown/containers/DropdownContainer';
import InputContainer from '@components/common/Input/containers/InputContainer';
import LineChartContainer from '@components/common/LineChart/containers/LineChartContainer';
import TextChip from '@components/common/TextChip/TextChip';
import ToggleContainer from '@components/common/Toggle/containers/ToggleContainer';
import { Serie } from '@nivo/line';
import { BarData } from '@typedef/bar.chart.types';
import { RobotDailyInfoType } from '@typedef/robot.info.types';
import React from 'react';
import Tooltip from './components/Tooltip';
import './styles/home.style.css';

type Props = {
  ppDateToggle: number;
  ppCountToggle: number;
  peDateToggle: number;
  peCountToggle: number;
  ppDateToggleClicked: (index: number) => void;
  ppCountToggleClicked: (index: number) => void;
  peDateToggleClicked: (index: number) => void;
  peCountToggleClicked: (index: number) => void;
  selectedOption: number;
  onOptionSelected: (index: number) => void;
  throughput: string;
  onThroughputChange: (value: string) => void;
  price: string;
  onPriceChange: (value: string) => void;
  onCategorySelected: (index: number) => void;
  deviceCategory: number;
  dummyData: RobotDailyInfoType | undefined;
  PPLineData: Serie[];
  PELineData: Serie[];
  dailyPriceLineData: Serie[];
  PPBarChart: BarData[];
  PEBarChart: BarData[];
  ppDailyIncrease: number;
  peDailyIncrease: number;
  profit: number;
  onCalcClicked: () => void;
};

const HomeLayout = ({
  ppDateToggle,
  ppCountToggle,
  peDateToggle,
  peCountToggle,
  ppDateToggleClicked,
  ppCountToggleClicked,
  peDateToggleClicked,
  peCountToggleClicked,
  selectedOption,
  onOptionSelected,
  throughput,
  onThroughputChange,
  price,
  onPriceChange,
  onCategorySelected,
  deviceCategory,
  dummyData,
  PPLineData,
  PELineData,
  dailyPriceLineData,
  PPBarChart,
  PEBarChart,
  ppDailyIncrease,
  peDailyIncrease,
  profit,
  onCalcClicked,
}: Props) => {
  console.log(PEBarChart);
  return (
    <div className='home-root'>
      <header className='category-section'>
        <div className='category-container'>
          <CategoryContainer
            options={['전체 대시보드', 'Wim 1번 bot']}
            activeIndex={deviceCategory}
            onOptionSelected={onCategorySelected}
          />
        </div>
      </header>
      <div className='home-layout'>
        <section className='picking-section'>
          <div className='header'>Day Picking</div>
          <div className='content'>
            <div className='pp'>
              <div className='wrapper'>
                <div className='counter'>
                  <div className='type'>PP</div>
                  <div className='count'>
                    {(dummyData?.robotInfoDaily.pp || 0).toLocaleString() +
                      '개'}
                  </div>
                  <ColorChip
                    amount={ppDailyIncrease}
                    sign={ppDailyIncrease < 0 ? 'minus' : 'plus'}
                  />
                </div>

                <div className='linechart-container'>
                  <LineChartContainer data={PPLineData} yFormatter={'X 개'} />
                </div>
              </div>
              <div className='counter-by-time'>
                <div className='item'>
                  <div className='type'>분당</div>
                  <div className='count'>
                    {(dummyData?.robotInfoDaily.ppPerMinute || 0) + '개'}
                  </div>
                  <ColorChip
                    amount={dummyData?.robotInfoDaily.ppPerMinuteDiff || 0}
                    sign={
                      dummyData?.robotInfoDaily.ppPerMinuteDiff || 0 > 0
                        ? 'plus'
                        : 'minus'
                    }
                  />
                </div>
                <div className='item'>
                  <div className='type'>시간당</div>
                  <div className='count'>
                    {(
                      dummyData?.robotInfoDaily.ppPerHour || 0
                    ).toLocaleString() + '개'}
                  </div>
                  <ColorChip
                    amount={dummyData?.robotInfoDaily.ppPerHourDiff || 0}
                    sign={
                      dummyData?.robotInfoDaily.ppPerHourDiff || 0 > 0
                        ? 'plus'
                        : 'minus'
                    }
                  />
                </div>
              </div>
            </div>
            <div className='divider'></div>
            <div className='pe'>
              <div className='wrapper'>
                <div className='counter'>
                  <div className='type'>PE</div>
                  <div className='count'>
                    {(dummyData?.robotInfoDaily.pe || 0).toLocaleString() +
                      '개'}
                  </div>
                  <ColorChip
                    amount={peDailyIncrease}
                    sign={peDailyIncrease < 0 ? 'minus' : 'plus'}
                  />
                </div>
                <div className='linechart-container'>
                  <LineChartContainer data={PELineData} yFormatter={'X 개'} />
                </div>
              </div>
              <div className='counter-by-time'>
                <div className='item'>
                  <div className='type'>분당</div>
                  <div className='count'>
                    {(
                      dummyData?.robotInfoDaily.pePerMinute || 0
                    ).toLocaleString() + '개'}
                  </div>
                  <div className='chip-container'>
                    <ColorChip
                      amount={dummyData?.robotInfoDaily.pePerMinuteDiff || 0}
                      sign={
                        dummyData?.robotInfoDaily.pePerMinuteDiff || 0 < 0
                          ? 'minus'
                          : 'plus'
                      }
                    />
                  </div>
                </div>
                <div className='item'>
                  <div className='type'>시간당</div>
                  <div className='count'>
                    {(
                      dummyData?.robotInfoDaily.pePerHour || 0
                    ).toLocaleString() + '개'}
                  </div>
                  <div className='chip-container'>
                    <ColorChip
                      amount={dummyData?.robotInfoDaily.pePerHourDiff || 0}
                      sign={
                        dummyData?.robotInfoDaily.pePerHourDiff || 0 < 0
                          ? 'minus'
                          : 'plus'
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='profit-section'>
          <div className='header'>예상수익</div>
          <div className='content'>
            <div className='display'>
              <TextChip title={'PP'} />
              <div className='result'>{'₩' + profit.toLocaleString()}</div>
            </div>
            <div className='chart-container'>
              <LineChartContainer
                data={dailyPriceLineData}
                yFormatter={'X 원'}
              />
            </div>
            <div className='calculator'>
              <div className='item'>
                <span className='label'>품목</span>
                <div className='dropbox-container'>
                  <DropdownContainer
                    options={['PP', 'PE']}
                    onOptionSelected={onOptionSelected}
                    activeIndex={selectedOption}
                  />
                </div>
              </div>
              <div className='item'>
                <span className='label'>처리량(kg)</span>
                <div className='input-container'>
                  <InputContainer
                    value={throughput}
                    onChange={onThroughputChange}
                    placeholder='처리량을 입력해주세요'
                  />
                </div>
              </div>
              <div className='item'>
                <div className='label'>
                  <span>시세(원)</span>
                  <img src='/assets/icons/ic-question.png' alt='ic_question' />
                  <Tooltip text='2022.12 재활용 가능자원 가격조사 기준 (300원=1kg)' />
                </div>
                <div className='input-container'>
                  <InputContainer
                    value={price}
                    onChange={onPriceChange}
                    placeholder='시세를 입력해주세요'
                  />
                </div>
              </div>
              <div className='item'>
                <span className='label'></span>
                <button className='calc-button' onClick={onCalcClicked}>
                  계산하기
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className='pp-section'>
          <div className='header'>PP 처리량</div>
          <div className='content'>
            <div className='toggle-area'>
              <div className='toggle-container'>
                <ToggleContainer
                  items={['일별', '주별', '월별']}
                  activeIndex={ppDateToggle}
                  onToggleButtonClicked={ppDateToggleClicked}
                />
              </div>
              <div className='toggle-container'>
                <ToggleContainer
                  items={['개수', 'Kg']}
                  activeIndex={ppCountToggle}
                  onToggleButtonClicked={ppCountToggleClicked}
                />
              </div>
            </div>
            <div className='chart-container'>
              <BarChartContainer
                data={PPBarChart}
                indexBy={'date'}
                valueKey={'count'}
              />
            </div>
          </div>
        </section>
        <section className='pe-section'>
          <div className='header'>PE 처리량</div>
          <div className='content'>
            <div className='toggle-area'>
              <div className='toggle-container'>
                <ToggleContainer
                  items={['일별', '주별', '월별']}
                  activeIndex={peDateToggle}
                  onToggleButtonClicked={peDateToggleClicked}
                />
              </div>
              <div className='toggle-container'>
                <ToggleContainer
                  items={['개수', 'Kg']}
                  activeIndex={peCountToggle}
                  onToggleButtonClicked={peCountToggleClicked}
                />
              </div>
            </div>
            <div className='chart-container'>
              <BarChartContainer
                data={PEBarChart}
                indexBy={'date'}
                valueKey={'count'}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HomeLayout;
