import useWindowSize from '@hooks/useWindowSize';
import { PageType } from '@typedef/page.types';
import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../Header';

type Props = {};

const HeaderContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const isMobile = useMemo(() => width <= 800, [width]);

  const onToggleButtonClicked = useCallback((index: number) => {
    if (index === 0) {
      navigate('/dashboard');
    } else {
      navigate('/video');
    }
  }, []);

  return (
    <Header
      isMobile={isMobile}
      page={location.pathname.split('/')[1] as PageType}
      onToggleButtonClicked={onToggleButtonClicked}
    />
  );
};

export default HeaderContainer;
