import { Point, ResponsiveLine, Serie } from '@nivo/line';
import React from 'react';
import Tooltip from './components/Tooltip';
import './styles/line.chart.style.css';

type Props = {
  value: string;
  data: Serie[];
  index: number | null;
  yFormatter?: string;
  onMouseMove: (point: Point) => void;
  onMouseLeave: () => void;
};

const LineChart = ({
  data,
  value,
  index,
  yFormatter,
  onMouseMove,
  onMouseLeave,
}: Props) => {
  return (
    <div className='linechart-root'>
      <ResponsiveLine
        data={data} //data
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }} // margin: 상위 태그 기준으로 안의 그래프 패딩 설정
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: true,
          reverse: false,
        }}
        lineWidth={1}
        isInteractive
        layers={['mesh', 'lines', 'points']} //각 구성요소를 합쳐서 표기
        //areas: 구역
        //axes: 축
        //mesh: 호버시 보이는 라벨
        //crosshair: 호버시 보이는 선
        //grid: 그래프 간격선
        //legends: 그래프 라벨
        tooltip={({ point }) => (
          <Tooltip
            point={point}
            xFormatted={data[0].data[point.index].xFormatted}
            yFormatter={yFormatter}
          />
        )}
        enablePoints
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        pointSymbol={() => (
          <circle
            className={`point-circle-${value}`}
            style={{ transition: 'scale 4s ease-in-out' }}
            r={1}
          />
        )}
        colors={'#000'}
        useMesh={true}
      />
    </div>
  );
};

export default LineChart;
