import { Serie } from '@nivo/line';
import { BarData } from '@typedef/bar.chart.types';
import { RobotDailyInfoType } from '@typedef/robot.info.types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useApi } from './useApi';

const today = new Date();

const weekList = [
  new Date(new Date().setDate(today.getDate() - 6)),
  new Date(new Date().setDate(today.getDate() - 5)),
  new Date(new Date().setDate(today.getDate() - 4)),
  new Date(new Date().setDate(today.getDate() - 3)),
  new Date(new Date().setDate(today.getDate() - 2)),
  new Date(new Date().setDate(today.getDate() - 1)),
  today,
];

const weeklyLabel = ['4주 전', '3주 전', '2주 전', '1주 전', '이번주'];

const monthlyLabel = [
  new Date(new Date().setMonth(today.getMonth() - 11)),
  new Date(new Date().setMonth(today.getMonth() - 10)),
  new Date(new Date().setMonth(today.getMonth() - 9)),
  new Date(new Date().setMonth(today.getMonth() - 8)),
  new Date(new Date().setMonth(today.getMonth() - 7)),
  new Date(new Date().setMonth(today.getMonth() - 6)),
  new Date(new Date().setMonth(today.getMonth() - 5)),
  new Date(new Date().setMonth(today.getMonth() - 4)),
  new Date(new Date().setMonth(today.getMonth() - 3)),
  new Date(new Date().setMonth(today.getMonth() - 2)),
  new Date(new Date().setMonth(today.getMonth() - 1)),
  today,
];

const emptyLineData: Serie[] = [
  {
    id: 'empty',
    data: [
      {
        xFormatted: '4시간 전',
        yFormatted: '0 개',
      },
      {
        xFormatted: '3시간 전',
        yFormatted: '0 개',
      },
      {
        xFormatted: '2시간 전',
        yFormatted: '0 개',
      },
      {
        xFormatted: '2시간 전',
        yFormatted: '0 개',
      },
      {
        xFormatted: '1시간 전',
        yFormatted: '0 개',
      },
    ],
  },
];

const emptyBarData: BarData[] = [
  {
    date: '7일전',
    count: 0,
  },
  {
    date: '6일전',
    count: 0,
  },
  {
    date: '5일전',
    count: 0,
  },
  {
    date: '4일전',
    count: 0,
  },
  {
    date: '3일전',
    count: 0,
  },
  {
    date: '2일전',
    count: 0,
  },
  {
    date: '1일전',
    count: 0,
  },
];
export default function useDummyData(
  category: number,
  trashCategory: number,
  ppDateOption: number,
  ppUnitOption: number,
  peDateOption: number,
  peUnitOption: number,
) {
  const { __getDummyData } = useApi();
  const [dummyData, setDummyData] = useState<RobotDailyInfoType>();

  const createRandomValue = useCallback((from: number, to: number) => {
    return Math.floor(Math.random() * (to - from + 1)) + from;
  }, []);

  //createPPLineData
  const PPLineData: Serie[] = useMemo(() => {
    const ppHistory = dummyData?.robotInfoDaily.ppHistory;
    if (!ppHistory) {
      return emptyLineData;
    }
    const data = ppHistory.map((value, index) => ({
      x: ppHistory.length - index,
      y: value,
      xFormatted: `${ppHistory.length - index}시간 전`,
      yFormatted: `${value} 개`,
    }));
    return [{ id: 'PPLineData', data: data }];
  }, [dummyData]);
  //createPELineData
  const PELineData: Serie[] = useMemo(() => {
    const peHistory = dummyData?.robotInfoDaily.peHistory;
    if (!peHistory) {
      return emptyLineData;
    }
    const data = peHistory.map((value, index) => ({
      x: peHistory.length - index,
      y: value,
      xFormatted: `${peHistory.length - index}시간 전`,
      yFormatted: `${value} 개`,
    }));
    return [{ id: 'PELineData', data: data }];
  }, [dummyData]);
  //createPriceLineData
  const PPpriceLineData: Serie[] = useMemo(() => {
    //*40원
    return [
      {
        id: 'PPpriceData',
        data: [
          {
            x: 0,
            xFormatted: '4일 전',
            y: (PPLineData[0].data[0].y as number) * 40,
          },
          {
            x: 1,
            xFormatted: '3일 전',
            y: (PPLineData[0].data[1].y as number) * 40,
          },
          {
            x: 2,
            xFormatted: '2일 전',
            y: (PPLineData[0].data[2].y as number) * 40,
          },
          {
            x: 3,
            xFormatted: '2일 전',
            y: (PPLineData[0].data[3].y as number) * 40,
          },
          {
            x: 4,
            xFormatted: '1일 전',
            y: (PPLineData[0].data[4].y as number) * 40,
          },
        ],
      },
    ];
  }, [PPLineData]);
  const PEpriceLineData: Serie[] = useMemo(() => {
    //*40원
    return [
      {
        id: 'PEpriceData',
        data: [
          {
            x: 0,
            xFormatted: '4일 전',
            y: (PELineData[0].data[0].y as number) * 40,
          },
          {
            x: 1,
            xFormatted: '3일 전',
            y: (PELineData[0].data[1].y as number) * 40,
          },
          {
            x: 2,
            xFormatted: '2일 전',
            y: (PELineData[0].data[2].y as number) * 40,
          },
          {
            x: 3,
            xFormatted: '2일 전',
            y: (PELineData[0].data[3].y as number) * 40,
          },
          {
            x: 4,
            xFormatted: '1일 전',
            y: (PELineData[0].data[4].y as number) * 40,
          },
        ],
      },
    ];
  }, [PELineData]);
  //createPPdailycountBarData
  const PPdailycountBarData: BarData[] = useMemo(() => {
    const ppHistory = dummyData?.robotInfoTermPP.count;
    if (!ppHistory) {
      return emptyBarData;
    }
    const data = weekList.map((date, index) => ({
      date: `${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}.${date.getDate()}`,
      count: ppHistory[index],
    }));
    return data;
  }, [dummyData]);
  //createPPdailyweightBarData
  const PPdailyweightBarData: BarData[] = useMemo(() => {
    const ppHistory = dummyData?.robotInfoTermPP.weight;
    if (!ppHistory) {
      return emptyBarData;
    }
    const data = weekList.map((date, index) => ({
      date: `${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}.${date.getDate()}`,
      count: Math.ceil(ppHistory[index] / 1000),
    }));
    return data;
  }, [dummyData]);
  //createPPweeklycountBarData
  const PPweeklycountBarData: BarData[] = useMemo(() => {
    //211000 - 240000 random
    if (!dummyData) {
      return emptyBarData;
    }
    const data = weeklyLabel.map((label, index) => ({
      date: label,
      count: createRandomValue(211, 240),
    }));
    return data;
  }, [dummyData]);
  //createPPweeklyweightBarData
  const PPweeklyweightBarData: BarData[] = useMemo(() => {
    //211000 - 240000 random
    if (!dummyData) {
      return emptyBarData;
    }
    const data = weeklyLabel.map((label, index) => ({
      date: label,
      count: createRandomValue(211 * 280, 240 * 280),
    }));
    return data;
  }, [dummyData]);
  //createPPmonthlycounttBarData
  const PPmonthlycontBarData: BarData[] = useMemo(() => {
    //211000 - 240000 random
    if (!dummyData) {
      return emptyBarData;
    }
    const data = monthlyLabel.map((date, index) => ({
      date: `${date.getMonth() + 1}월`,
      count: createRandomValue(211000, 24000),
    }));
    return data;
  }, [dummyData]);
  //createPPmonthlyweightBarData
  const PPmonthlyweightBarData: BarData[] = useMemo(() => {
    //211000 - 240000 random
    if (!dummyData) {
      return emptyBarData;
    }
    const data = monthlyLabel.map((date, index) => ({
      date: `${date.getMonth() + 1}월`,
      count: createRandomValue(211 * 1200, 240 * 1200),
    }));
    return data;
  }, [dummyData]);
  //createPEdailycountBarData
  const PEdailycountBarData: BarData[] = useMemo(() => {
    const peHistory = dummyData?.robotInfoTermPE.count;
    if (!peHistory) {
      return emptyBarData;
    }
    const data = weekList.map((date, index) => ({
      date: `${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}.${date.getDate()}`,
      count: peHistory[index],
    }));
    return data;
  }, [dummyData]);
  //createPEdailyweightBarData
  const PEdailyweightBarData: BarData[] = useMemo(() => {
    const peHistory = dummyData?.robotInfoTermPE.weight;

    if (!peHistory) {
      return emptyBarData;
    }
    const data = weekList.map((date, index) => ({
      date: `${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}.${date.getDate()}`,
      count: Math.ceil(peHistory[index] / 1000),
    }));
    return data;
  }, [dummyData]);
  //createPEweeklycountBarData
  const PEweeklycountBarData: BarData[] = useMemo(() => {
    //211000 - 240000 random
    if (!dummyData) {
      return emptyBarData;
    }
    const data = weeklyLabel.map((label, index) => ({
      date: label,
      count: createRandomValue(211000, 24000),
    }));
    return data;
  }, [dummyData]);
  //createPEweeklyweightBarData
  const PEweeklyweightBarData: BarData[] = useMemo(() => {
    //211000 - 240000 random
    if (!dummyData) {
      return emptyBarData;
    }
    const data = weeklyLabel.map((label, index) => ({
      date: label,
      count: createRandomValue(211 * 280, 240 * 280),
    }));
    return data;
  }, [dummyData]);
  //createPEmonthlycountBarData
  const PEmonthlycontBarData: BarData[] = useMemo(() => {
    //211000 - 240000 random
    if (!dummyData) {
      return emptyBarData;
    }
    const data = monthlyLabel.map((date, index) => ({
      date: `${date.getMonth() + 1}월`,
      count: createRandomValue(211000, 24000),
    }));
    return data;
  }, [dummyData]);
  //createPEmonthlyweightBarData
  const PEmonthlyweightBarData: BarData[] = useMemo(() => {
    //211000 - 240000 random
    if (!dummyData) {
      return emptyBarData;
    }
    const data = monthlyLabel.map((date, index) => ({
      date: `${date.getMonth() + 1}월`,
      count: createRandomValue(211 * 1200, 240 * 1200),
    }));
    return data;
  }, [dummyData]);
  const ppDailyIncrease = useMemo(
    () =>
      (PPdailycountBarData[6].count as number) -
      (PPdailycountBarData[5].count as number),
    [PPdailycountBarData],
  );
  const peDailyIncrease = useMemo(
    () =>
      (PEdailycountBarData[6].count as number) -
      (PEdailycountBarData[5].count as number),
    [PEdailycountBarData],
  );

  const dailyPriceLineData = useMemo(
    () => (trashCategory === 0 ? PPpriceLineData : PEpriceLineData),
    [PEpriceLineData, PPpriceLineData, trashCategory],
  );

  const PPBarChart = useMemo(() => {
    switch (ppUnitOption * 3 + ppDateOption) {
      case 0:
        return PPdailycountBarData;
      case 1:
        return PPweeklycountBarData;
      case 2:
        return PPmonthlycontBarData;
      case 3:
        return PPdailyweightBarData;
      case 4:
        return PPweeklyweightBarData;
      case 5:
        return PPmonthlyweightBarData;
      default:
        return emptyBarData;
    }
  }, [
    ppDateOption,
    ppUnitOption,
    PPdailycountBarData,
    PPweeklycountBarData,
    PPmonthlycontBarData,
    PPdailyweightBarData,
    PPweeklyweightBarData,
    PPmonthlyweightBarData,
  ]);

  const PEBarChart = useMemo(() => {
    switch (peUnitOption * 3 + peDateOption) {
      case 0:
        return PEdailycountBarData;
      case 1:
        return PEweeklycountBarData;
      case 2:
        return PEmonthlycontBarData;
      case 3:
        return PEdailyweightBarData;
      case 4:
        return PEweeklyweightBarData;
      case 5:
        return PEmonthlyweightBarData;
      default:
        return emptyBarData;
    }
  }, [
    peDateOption,
    peUnitOption,
    PEdailycountBarData,
    PEweeklycountBarData,
    PEmonthlycontBarData,
    PEdailyweightBarData,
    PEweeklyweightBarData,
    PEmonthlyweightBarData,
  ]);

  useEffect(() => {
    //로봇 변경될때마다 더미데이터 초기화
    console.log('dummy refresh');
    __getDummyData().then((res) => {
      setDummyData(res);
    });
  }, [category]);

  return {
    dummyData,
    PPLineData,
    PELineData,
    dailyPriceLineData,
    PEBarChart,
    PPBarChart,
    ppDailyIncrease,
    peDailyIncrease,
  };
}
