import React from 'react';
import './styles/input.style.css';

type Props = {
  placeholder?: string;
  type?: 'number' | 'text' | 'password';
  value: string;
  onValueChange: (value: string) => void;
};

const Input = ({ placeholder = '', type, value, onValueChange }: Props) => {
  return (
    <input
      type={type === 'number' ? 'text' : type}
      className={'input-root ' + (value.length !== 0 ? 'filled' : '')}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onValueChange(e.target.value)}
    />
  );
};

export default Input;
