import { useToken } from '@hooks/useToken';
import { apiRoute, requestSecureGet } from '@lib/apis';
import { RobotDailyInfoType } from '@typedef/robot.info.types';
import { useCallback } from 'react';

export function useApi() {
  const { __getToken } = useToken();

  const __getDummyData = useCallback(async () => {
    const { accessToken } = __getToken();
    const {
      data,
      config: { status, message },
    } = await requestSecureGet<RobotDailyInfoType>(
      apiRoute.dummy,
      {},
      accessToken,
    );

    if (status !== 200) {
      return;
    }
    return data;
  }, []);

  return {
    __getDummyData,
  };
}
