import InputContainer from '@components/common/Input/containers/InputContainer';
import React from 'react';
import './styles/login.style.css';

type Props = {
  id: string;
  password: string;
  onIdChange: (value: string) => void;
  onPasswordChange: (value: string) => void;
  onLoginSubmit: (e: React.FormEvent) => void;
};

const Login = ({
  id,
  password,
  onIdChange,
  onPasswordChange,
  onLoginSubmit,
}: Props) => {
  return (
    <div className='login-root'>
      <div className='title-container'>
        <div className='title'>
          W-ecobot
          <br /> Monitoring System
        </div>
        <div className='subtitle'>윔 에코봇 모니터링 시스템</div>
      </div>
      <form className='login-form' onSubmit={onLoginSubmit}>
        <div className='header'>로그인</div>
        <div className='item'>
          <div className='label'>아이디</div>
          <div className='input-container'>
            <InputContainer
              type='text'
              placeholder='아이디를 입력해주세요'
              value={id}
              onChange={onIdChange}
            />
          </div>
        </div>
        <div className='item'>
          <div className='label'>비밀번호</div>
          <div className='input-container'>
            <InputContainer
              type='password'
              placeholder='비밀번호를 입력해주세요'
              value={password}
              onChange={onPasswordChange}
            />
          </div>
        </div>
        <button className='login-button' type='submit' onClick={onLoginSubmit}>
          로그인
        </button>
      </form>
    </div>
  );
};

export default Login;
