import React, { useEffect, useRef, useState } from 'react';
import Toggle from '../Toggle';

type Props = {
  /**
   * 토글의 항목들 목록입니다
   */
  items: string[];
  /**
   * 현재 선택된 항목의 인덱스입니다.
   * base는 0입니다
   */
  activeIndex: number;
  /**
   * 토글의 버튼을 클릭했을때 발생하는 이벤트입니다.
   */
  onToggleButtonClicked: (index: number) => void;
};

const ToggleContainer = (props: Props) => {
  const [ballWidth, setBallWidth] = useState(0);

  const ballRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ballRef.current) {
      setBallWidth(ballRef.current.clientWidth);
    }

    return () => {};
  }, [ballRef]);

  return <Toggle {...props} ballWidth={ballWidth} ballRef={ballRef} />;
};

export default ToggleContainer;
