import React from 'react';
import './styles/toggle.style.css';

type Props = {
  items: string[];
  activeIndex: number;
  onToggleButtonClicked: (index: number) => void;
  ballWidth: number;
  ballRef: React.RefObject<HTMLDivElement>;
};

const Toggle = ({
  items,
  activeIndex,
  onToggleButtonClicked,
  ballWidth,
  ballRef,
}: Props) => {
  return (
    <div className='toggle-root'>
      <div className='ball-layer'>
        {items.map((_, index) =>
          index === 0 ? (
            <div
              className='ball'
              key={index}
              style={{
                transform: `translateX(${activeIndex * (ballWidth + 10)}px)`,
              }}
              ref={ballRef}
            />
          ) : (
            <div key={index} className='empty' />
          ),
        )}
      </div>
      <div className='text-layer'>
        {items.map((item, index) => (
          <button
            key={item}
            onClick={() => onToggleButtonClicked(index)}
            className={`text-container ${
              activeIndex === index ? 'active' : ''
            }`}>
            {item}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Toggle;
