import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../hooks/useApi';
import Login from '../Login';

type Props = {};

const LoginContainer = (props: Props) => {
  const { __useLoginApi } = useApi();

  const navigate = useNavigate();

  const [id, setId] = useState('');
  const [password, setPassword] = useState('');

  const onIdChange = useCallback((value: string) => {
    console.log('id', value);
    setId(value);
  }, []);

  const onPasswordChange = useCallback((value: string) => {
    setPassword(value);
  }, []);

  const onLoginSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      const result = await __useLoginApi(id, password);
      if (!result) {
        alert('아이디나 비밀번호가 틀렸습니다. 다시 시도해주세요');
        setId('');
        setPassword('');
      } else {
        navigate('/dashboard');
      }
    },
    [id, password, __useLoginApi],
  );

  return (
    <Login
      id={id}
      password={password}
      onIdChange={onIdChange}
      onPasswordChange={onPasswordChange}
      onLoginSubmit={onLoginSubmit}
    />
  );
};

export default LoginContainer;
