import React from 'react';
import VideoItem from './components/VideoItem';
import './styles/video.layout.css';

type Props = {
  currentIndex: number;
};

const VideoLayout = ({ currentIndex }: Props) => {
  return (
    <div className='video-layout'>
      <section className='title-section'>기기별 실시간 처리장 영상</section>
      {/* TODO 비디오 확대 기능 */}
      <section className='video-section'>
        {[1, 2, 3, 4].map((value, index) => (
          <VideoItem key={value} currentIndex={currentIndex} index={index} />
        ))}
      </section>
    </div>
  );
};

export default VideoLayout;
