import { useCallback, useState } from 'react';

export default function useDropdown() {
  const [option, setOption] = useState(0);

  const onDropdownSelected = useCallback((index: number) => {
    setOption(index);
  }, []);

  return {
    option,
    onDropdownSelected,
  };
}
