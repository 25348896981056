import { useCallback, useState } from 'react';

export default function useControl() {
  const [isExpanded, setIsExpanded] = useState(false);

  const onButtonClicked = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  return {
    isExpanded,
    onButtonClicked,
  };
}
