import VideoDownsize from '@components/common/VideoDownsize/VideoDownsize';
import VideoExpand from '@components/common/VideoExpand/VideoExpand';
import React from 'react';
import useControl from '../hooks/useControl';
import '../styles/video.item.css';

type Props = {
  currentIndex: number;
  index: number;
};

const VideoItem = ({ index, currentIndex }: Props) => {
  //todo: 클릭시 전체화면을 표시하는 다른 페이지로
  const { isExpanded, onButtonClicked } = useControl();

  return (
    <div className={`video-item ${isExpanded ? 'expanded' : ''}`}>
      <div className='header'>
        <div className='index-box'>{index + 1}</div>
        <div className='device-name'>Bot 1 name</div>
      </div>
      <div className='content'>
        <img
          src='/assets/images/img-example@3x.png'
          className='video-layer'></img>
        <div className='control-layer'>
          <div className='size-btn'>
            {!isExpanded && <VideoExpand onClicked={onButtonClicked} />}
            {isExpanded && <VideoDownsize onClicked={onButtonClicked} />}
          </div>
          <div className='power-state on'>
            <div className='state'></div>
            <div className='text'>On</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoItem;
