import HeaderContainer from '@components/common/Header/containers/HeaderContainer';
import React from 'react';
import { Outlet } from 'react-router-dom';

type Props = {};

const Layout = (props: Props) => {
  return (
    <>
      <HeaderContainer />
      <Outlet />
    </>
  );
};

export default Layout;
