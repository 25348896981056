import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToken } from './useToken';

export function useRoute() {
  const navigate = useNavigate();

  const { __getToken } = useToken();

  const __checkRoutePermission = useCallback(() => {
    const { accessToken } = __getToken();

    if (accessToken === '') {
      navigate('/login');
    }
  }, []);

  return {
    __checkRoutePermission,
  };
}
