import { useRoute } from '@hooks/useRoute';
import React, { useCallback, useEffect, useState } from 'react';
import HomeLayout from '../HomeLayout';
import useDropdown from '../hooks/useDropdown';
import useDummyData from '../hooks/useDummyData';
import useInput from '../hooks/useInput';
import useToggle from '../hooks/useToggle';

type Props = {};

const HomeContainer = (props: Props) => {
  const [profit, setProfit] = useState(0);
  const { __checkRoutePermission } = useRoute();

  const ppDateToggle = useToggle();
  const ppCountToggle = useToggle(2);
  const peDateToggle = useToggle();
  const peCountToggle = useToggle(2);

  const throughput = useInput();
  const price = useInput();

  const trashCategory = useDropdown();
  const deviceCategory = useDropdown();

  const {
    dummyData,
    PPLineData,
    PELineData,
    dailyPriceLineData,
    PPBarChart,
    PEBarChart,
    ppDailyIncrease,
    peDailyIncrease,
  } = useDummyData(
    deviceCategory.option,
    trashCategory.option,
    ppDateToggle.toggleIndex,
    ppCountToggle.toggleIndex,
    peDateToggle.toggleIndex,
    peCountToggle.toggleIndex,
  );

  const onCalcClicked = useCallback(() => {
    //Todo: 계산기 고장남
    setProfit(Number(throughput.value) * Number(price.value));
    throughput.onValueChanged('');
    price.onValueChanged('');
  }, [throughput.value, price.value]);

  useEffect(() => {
    __checkRoutePermission();
  }, []);

  useEffect(() => {
    throughput.onValueChanged('');
    price.onValueChanged('');

    return () => {};
  }, [trashCategory.option]);

  return (
    <HomeLayout
      ppDateToggle={ppDateToggle.toggleIndex}
      ppCountToggle={ppCountToggle.toggleIndex}
      peDateToggle={peDateToggle.toggleIndex}
      peCountToggle={peCountToggle.toggleIndex}
      ppDateToggleClicked={ppDateToggle.onToggleClicked}
      ppCountToggleClicked={ppCountToggle.onToggleClicked}
      peDateToggleClicked={peDateToggle.onToggleClicked}
      peCountToggleClicked={peCountToggle.onToggleClicked}
      selectedOption={trashCategory.option}
      onOptionSelected={trashCategory.onDropdownSelected}
      throughput={throughput.value}
      onThroughputChange={throughput.onValueChanged}
      price={price.value}
      onPriceChange={price.onValueChanged}
      deviceCategory={deviceCategory.option}
      onCategorySelected={deviceCategory.onDropdownSelected}
      dummyData={dummyData}
      PPLineData={PPLineData}
      PELineData={PELineData}
      dailyPriceLineData={dailyPriceLineData}
      PPBarChart={PPBarChart}
      PEBarChart={PEBarChart}
      ppDailyIncrease={ppDailyIncrease}
      peDailyIncrease={peDailyIncrease}
      profit={profit}
      onCalcClicked={onCalcClicked}
    />
  );
};

export default HomeContainer;
