import React, { useCallback, useState } from 'react';
import Category from '../Category';

type Props = {
  /**
   * 드랍다운 아래에 표시되는 항목리스트입니다.
   */
  options: string[];
  /**
   * 현재 선택된 항목의 인덱스입니다.
   */
  activeIndex: number;
  /**
   * 항목을 선택했을때 발생이벤트입니다.
   */
  onOptionSelected: (index: number) => void;
};

/**
 * 가로길이는 부모 컴포넌트의 길이에 맞춰집니다
 */
const CategoryContainer = (props: Props) => {
  const [isFocused, setIsFocused] = useState(false);

  const onDropdownClicked = useCallback(() => {
    setIsFocused((prev) => !prev);
  }, []);
  return (
    <Category
      {...props}
      isFocused={isFocused}
      onDropdownClicked={onDropdownClicked}
    />
  );
};

export default CategoryContainer;
