import React from 'react';
import ToggleContainer from '../Toggle/containers/ToggleContainer';
import './styles/header.style.css';

type Props = {
  isMobile: boolean;
  page: 'dashboard' | 'video';
  onToggleButtonClicked: (index: number) => void;
};

const Header = ({ isMobile, page, onToggleButtonClicked }: Props) => {
  return (
    <div className='header-root'>
      <div className='content-wrapper'>
        <div className='left'>
          <div className='toggle-container'>
            <ToggleContainer
              items={['대시보드', '실시간영상']}
              activeIndex={page === 'dashboard' ? 0 : 1}
              onToggleButtonClicked={onToggleButtonClicked}
            />
          </div>
        </div>
        <div className='title'>
          {isMobile ? 'W-ecobot' : 'W-ecobot Monitoring System'}
        </div>
        <div className='right'>
          <span className='profile-name'>관리자 이름</span>
          <div className='default-img' />
        </div>
      </div>
    </div>
  );
};

export default Header;
