import React from 'react';
import './styles/video.downsize.css';

type Props = {
  onClicked: () => void;
};

const VideoDownsize = ({ onClicked }: Props) => {
  return (
    <button className='downsize-component' onClick={onClicked}>
      <div className='piece-1' />
      <div className='piece-2' />
      <div className='piece-3' />
      <div className='piece-4' />
    </button>
  );
};

export default VideoDownsize;
