import { useCallback, useState } from 'react';

export default function useInput() {
  const [value, setValue] = useState('');

  const onValueChanged = useCallback((value: string) => {
    setValue(value);
  }, []);

  return {
    value,
    onValueChanged,
  };
}
