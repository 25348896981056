import React from 'react';
import './styles/text.chip.style.css';

type Props = {
  title: string;
};

const TextChip = ({ title }: Props) => {
  return <div className='textchip-root'>{title}</div>;
};

export default TextChip;
