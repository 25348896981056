import React, { useCallback, useEffect, useState } from 'react';
import Input from '../Input';

type Props = {
  placeholder?: string;
  /**
   * 기본값: number
   */
  type?: 'number' | 'text' | 'password';
  value: string | number;
  onChange: (value: string) => void;
};

const InputContainer = ({
  placeholder = '',
  type = 'number',
  value,
  onChange,
}: Props) => {
  const [formattedValue, setFormattedValue] = useState('');

  const onValueChange = useCallback(
    (value: string) => {
      if (value === '') {
        onChange('');
        setFormattedValue('');
        return;
      }
      if (type === 'number') {
        const formatted = value.replaceAll(',', '');
        if (!Number(formatted)) {
          return;
        }
        onChange(formatted);
      } else {
        setFormattedValue(value);
        onChange(value);
      }
    },
    [type, onChange],
  );

  useEffect(() => {
    if (type === 'number' && value) {
      setFormattedValue(Number(value).toLocaleString());
    }
  }, [type, value]);

  return (
    <Input
      placeholder={placeholder}
      type={type}
      value={formattedValue}
      onValueChange={onValueChange}
    />
  );
};

export default InputContainer;
