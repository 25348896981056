import HomeContainer from '@components/Home/containers/HomeContainer';
import LoginContainer from '@components/Login/containers/LoginContainer';
import VideoContainer from '@components/Video/containers/VideoContainer';
import React from 'react';
import { Route, Routes } from 'react-router';
import { BrowserRouter, Navigate } from 'react-router-dom';
import Layout from './compents/Layout';

const RootNavigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<LoginContainer />} />
        <Route element={<Layout />}>
          <Route path='/dashboard' element={<HomeContainer />} />
          <Route path='/video' element={<VideoContainer />} />
        </Route>
        <Route path='/' element={<Navigate to='/login' replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RootNavigation;
