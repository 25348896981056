import React from 'react';
import '../styles/border.tooltip.css';

type Props = {
  text: string;
};

const Tooltip = ({ text }: Props) => {
  return (
    <div className='bordered-tooltip-root'>
      <svg width={10} height={9}>
        <polygon
          points='5,0 0,8 10,8'
          style={{ fill: '#ffffff' }}
          stroke={'#eaeaea'}
        />
        <polygon points='5,1 0,9 10,9' style={{ fill: '#ffffff' }} />
      </svg>
      <div className='text'>{text}</div>
    </div>
  );
};

export default Tooltip;
