import { Point, Serie } from '@nivo/line';
import React, { useCallback, useEffect, useId, useState } from 'react';
import LineChart from '../LineChart';

type Props = {
  data: Serie[];
  yFormatter?: string;
};

const LineChartContainer = ({ data, yFormatter }: Props) => {
  const [index, setIndex] = useState<number | null>(null);
  const value = useId().replaceAll(':', '');

  const onMouseMove = useCallback((point: Point) => {
    setIndex(point.index);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIndex(null);
  }, []);

  const onIndexChanged = useCallback(() => {
    if (index == null) {
      return;
    }
    const pointCount = data[0].data.length;
    const nodes = document.querySelectorAll(`.point-circle-${value}`);
    (nodes[pointCount - 1 - index] as HTMLElement).style.scale = '5';
  }, [index, data, value]);

  useEffect(() => {
    onIndexChanged();

    return () => {};
  }, [onIndexChanged]);

  return (
    <LineChart
      value={value}
      data={data}
      index={index}
      yFormatter={yFormatter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
    />
  );
};

export default LineChartContainer;
