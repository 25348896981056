import { useToken } from '@hooks/useToken';
import { apiRoute, requestPost } from '@lib/apis';
import { LoginResponseType } from '@typedef/login.typs';
import { useCallback } from 'react';

export function useApi() {
  const { __updateToken } = useToken();

  const __useLoginApi = useCallback(
    async (userName: string, password: string) => {
      const {
        data,
        config: { status, message },
      } = await requestPost<LoginResponseType>(
        apiRoute.login,
        {},
        { userName, password },
      );

      console.log({ userName, password });
      if (status !== 200) {
        return false;
      }

      __updateToken(data.accessToken, data.refreshToken);

      return true;
    },
    [],
  );

  return {
    __useLoginApi,
  };
}
