import { useRoute } from '@hooks/useRoute';
import React, { useEffect, useState } from 'react';
import VideoLayout from '../VideoLayout';

type Props = {};

const VideoContainer = (props: Props) => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const { __checkRoutePermission } = useRoute();

  useEffect(() => {
    __checkRoutePermission();
  }, []);

  return <VideoLayout currentIndex={currentIndex} />;
};

export default VideoContainer;
