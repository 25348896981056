import React from 'react';
import './styles/bar.chart.style.css';

type Props = {
  ylabel: string;
  yaxes: (string | number)[];
  xaxes: (string | number)[];
  values: number[];
};

const BarChart = ({ ylabel, yaxes, xaxes, values }: Props) => {
  return (
    <div className='barchart-root'>
      <div className='upper-section'>
        <div className='yaxes'>
          <div className='label'>
            <span>{ylabel}</span>
          </div>
          <div className='ticks'>
            {yaxes.map((y, i) => (
              <div className='tick' key={i}>
                {y.toLocaleString()}
              </div>
            ))}
          </div>
        </div>
        <div className='graph'>
          <div className='grid-y-layer'>
            {[1, 2, 3, 4, 5].map((k) => (
              <div key={k} className='line-container'>
                <div className='line' />
              </div>
            ))}
          </div>
          <div className='bar-layer'>
            {values.map((value, index) => (
              <div className='bar-container' key={index}>
                <div
                  key={index}
                  className='bar'
                  style={{
                    height: `${
                      ((value + 1 - (yaxes[yaxes.length - 1] as number)) /
                        ((yaxes[0] as number) -
                          (yaxes[yaxes.length - 1] as number))) *
                      100
                    }%`,
                  }}>
                  <span className='bar-label'>{value.toLocaleString()}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='xaxes'>
        {xaxes.map((x, index) => (
          <div key={index} className='xaxis'>
            {x}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BarChart;
