import React, { useMemo } from 'react';
import './styles/color.chip.style.css';

type Props = {
  /**
   * 컬러칩에 표시되는 숫자입니다. 양수만 가능합니다.
   */
  amount: number;
  /**
   * 컬러칩이 음수인가 양수인가를 표시합니다.
   */
  sign: 'minus' | 'plus';
};

const ColorChip = ({ amount, sign }: Props) => {
  const isSmall = useMemo(() => amount.toString().length <= 2, [amount]);
  return (
    <div className={'colorchip-root ' + sign}>
      <span className='amount' style={{ fontSize: isSmall ? 12 : 14 }}>
        {Math.abs(amount)}
      </span>
      {sign === 'plus' && (
        <svg width={10} height={8}>
          <polygon points='5,0 0,8 10,8' style={{ fill: '#d32d41' }} />
        </svg>
      )}
      {sign === 'minus' && (
        <svg width={10} height={8}>
          <polygon points='5,8 0,0 10,0' style={{ fill: '#1a4575' }} />
        </svg>
      )}
    </div>
  );
};

export default ColorChip;
